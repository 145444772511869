import React from 'react'

function Cart() {
  return (
    <section class="row" id="cart">
        <h3>R$00,00</h3>
        <button onclick="alert('Olá')"> ENCOMENDAR </button>
    </section>
  )
}

export default Cart
