import React from 'react'

function Contacts() {
  return (
    <section id="contact">
        <p>
            Marmitas Congeladas Sem glúten e lactose
        </p>
        <p class="address">
            R. Senador Carlos Gomes de Oliveira, 550 - Distrito Industrial, São José - Santa Catarina
        </p>
        <div>
            <p><strong>Encomendas: </strong><a href="tel:+5548991488950">(48) 9 9148-8950</a></p>
            <p><strong>Suporte: </strong><a href="tel:+5548996966465">(48) 9 9696-6465</a></p>
            <p>Segunda à sexta 09:30 às 23:00</p>
        </div>
    </section>
  )
}

export default Contacts
