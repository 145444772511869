import React from 'react'
import Contacts from './Contacts'

function Footer() {
  return (
    <footer class="row">
       <Contacts/>
        
        <marquee behavior="" direction="left">
            <p>Feito por <a href="https://www.55technology.com" target="_blank"  rel="noopener noreferrer">55technology</a></p>
        </marquee>
    </footer>
  )
}

export default Footer
