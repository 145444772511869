import React from 'react'
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';

function Map() {
  const center = [-27.612523,-48.6436261]

  const polygon = [
    [-27.404623,-48.3036281], // Norte da ilha (mar)
    [-27.284623,-48.5036281], // Gov Celso Ramos (mar)
    [-27.304623,-48.7036281], // Gov Celso Ramos (terra)
    [-27.504623,-48.8036281], // Ant Carlos
    [-27.685623,-48.8556561], // Santo Amaro
    [-27.995623,-48.8056561], // Garopaba (terra)
    [-28.101009,-48.7254261], // Garopaba (meio L)
    [-28.100019,-48.6254261], // Garopaba (meio R)
    [-28.000009,-48.5654261], // Garopaba (mar)
  ]

  const limeOptions = { color: '#004719' }

  return (
    <section id="delivery-area">
     <MapContainer center={center} zoom={8.9} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Polygon pathOptions={limeOptions} positions={polygon} />
  </MapContainer>,
    </section>
  )  
}

export default Map
