import React from 'react'
import ProductDetail from './ProductDetail'

function ProductList() {
  return (
    <div>
      <main> 
            <section id="options" class="column">
                
                <div class="row">
                    <h2>Lasanhas</h2>
                </div>

                <div class="product-row">
                    <ProductDetail name="Lasanha de carne" amount="22,90" background="../img/lasanha-carne.jpeg"/>
                    <ProductDetail name="Lasanha de frango" amount="22,90" background="../img/lasanha-brocolis.jpeg"/>
                    <ProductDetail name="Lasanha de brocolis" amount="22,90" background="../img/lasanha-brocolis.jpeg"/>
                </div>

                <div class="row">
                    <h2>Strogonoff</h2>
                </div>

                <div class="product-row">
                    <ProductDetail name="Strogonoff de carne" amount="21,90" background="../img/strogonoff-carne.jpeg"/>
                    <ProductDetail name="Strogonoff de frango" amount="21,90" background="../img/strogonoff-frango.jpeg"/>
                </div>

    
                {/* <div class="row">
                    <h2></h2>
                </div>
                <div class="product-row">
                    <div class="product column" style="background-image: url('./src/img/strogonoff-carne.jpeg');">
                        <div class="product-info">
                            <h3>Strogonoff de Carne</h3>
                            <p><b>R$21,90</b></p>
                        </div>
                    </div>
                    <div class="product column" style="background-image: url('./src/img/strogonoff-frango.jpeg');">
                        <div class="product-info">
                            <h3>Strogonoff de Frango</h3>
                            <p><b>R$21,90</b></p>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <h2>Escondidinhos</h2>
                </div>
                <div class="product-row">
                    <div class="product column" style="background-image: url('./src/img/escondidinho-carne.jpeg');">
                        <div class="product-info">
                            <h3>Escondidinho de Carne</h3>
                            <p><b>R$20,90</b></p>
                        </div>
                    </div>
                    <div class="product column" style="background-image: url('./src/img/escondidinho.jpeg');">
                        <div class="product-info">
                            <h3>Escondidinho de Frango</h3>
                            <p><b>R$20,90</b></p>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <h2>Nhoques</h2>
                </div>
                <div class="product-row">
                    <div class="product column" style="background-image: url('./src/img/nhoque-carne.jpeg');">
                        <div class="product-info">
                            <h3>Nhoque de Carne</h3>
                            <p><b>R$18,90</b></p>
                        </div>
                    </div>
                    <div class="product column" style="background-image: url('./src/img/nhoque-frango.jpeg');">
                        <div class="product-info">
                            <h3>Nhoque de Frango</h3>
                            <p><b>R$18,90</b></p>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <h2>Panquecas</h2>
                </div>
                <div class="product-row">
                    <div class="product column" style="background-image: url('./src/img/panqueca-carne.jpeg');">
                        <div class="product-info">
                            <h3>Panqueca de carne</h3>
                            <p><b>R$18,90</b></p>
                        </div>
                    </div>
                    <div class="product column" style="background-image: url('./src/img/panqueca-frango.jpeg');">
                        <div class="product-info">
                            <h3>Panqueca de frango</h3>
                            <p><b>R$18,90</b></p>
                        </div>
                    </div>
                    <div class="product column" style="background-image: url('./src/img/panqueca-brocolis.jpeg');">
                        <div class="product-info">
                            <h3>Panqueca de brocolis</h3>
                            <p><b>R$18,90</b></p>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <h2>Purês</h2>
                </div>
                <div class="product-row">
                    <div class="product column" style="background-image: url('./src/img/pure-carne.jpeg');">
                        <div class="product-info">
                            <h3>Purê de batata com carne</h3>
                            <p><b>R$18,90</b></p>
                        </div>
                    </div>
                    <div class="product column" style="background-image: url('./src/img/pure-frango.jpeg');">
                        <div class="product-info">
                            <h3>Purê de batata com frango</h3>
                            <p><b>R$18,90</b></p>
                        </div>
                    </div>
                    <div class="product column" style="background-image: url('./src/img/pure-ab-carne.jpeg');">
                        <div class="product-info">
                            <h3>Purê de abobora com carne</h3>
                            <p><b>R$18,90</b></p>
                        </div>
                    </div>
                    <div class="product column" style="background-image: url('./src/img/pure-ab-frango.jpeg');">
                        <div class="product-info">
                            <h3>Purê de abobora com frango</h3>
                            <p><b>R$18,90</b></p>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <h2>Sobremesas</h2>
                </div>
                <div class="product-row">
                    <div class="product column" style="background-image: url('./src/img/mousse-maracuja.jpeg');">
                        <div class="product-info">
                            <h3>Mousse de maracujá</h3>
                            <p><b>R$9,90</b></p>
                        </div>
                    </div>
                    <div class="product column" style="background-image: url('./src/img/mousse-limao.jpeg');">
                        <div class="product-info">
                            <h3>Mousse de limão</h3>
                            <p><b>R$9,90</b></p>
                        </div>
                    </div>
                </div> */}
            </section>
        </main>
    </div>
  )
}

export default ProductList
