import React from 'react'

function scroll(id) {
    document.getElementById(id).scrollIntoView({behavior:'smooth'});
}

function Menu() {
  return (
    <div class="row menu">
        <a href="javascript:void(0); scroll('banner')">Cardápio</a>
        <a href="javascript:void(0); scroll('cart')">Carrinho</a>
        <a href="javascript:void(0); scroll('delivery-area')">Área de entrega</a>
        <a href="javascript:void(0); scroll('contact')">Contato</a>
    </div>
  )
}

export default Menu
