import React from 'react'
import { Fragment } from 'react'

function Header(props) {
  return (
    <Fragment>
      <nav class="column">
            <div class="row" id="delivery-bar"> 
                <p> 
                    Prazo de entrega até 3 dias úteis.
                </p>
            </div>
            
            <div id="banner">
              <img src="./img/logo-final.svg" alt="" style={{width:"350px"}}/>
            </div>
        </nav>
    </Fragment>
  )
}

export default Header
