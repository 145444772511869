import React from 'react'
import { Fragment } from 'react'

function ProductDetail(props) {
  return (
    <Fragment className="produto">
        <a href="/" class="product column" style={{ backgroundImage: `url(${props.background})` }}>
            <div class="product-info">
                <h3>{props.name}</h3>
                <p><b>R$ {props.amount}</b></p>
            </div>
        </a>
    </Fragment>
  )
}

export default ProductDetail
