import './App.css';
import Cart from './components/Cart';
import Footer from './components/Footer';
import Header from './components/Header';
import Map from './components/Map';
import Menu from './components/Menu';
import ProductList from './components/ProductList';
import Whats from './components/Whats';


function App() {
  return (
    <div className="App">

      {/* Banner */}
      <Header title="Cozinha & Cia"/>

      {/* Main Menu */}
      <Menu/>

      {/* Product List */}
      <ProductList/>

      {/* Cart - Carrinho de compras */}
      <Cart/>

      {/* Map - Área de entrega */}
      <Map/>

      {/* Botão fixo whatsapp */}
      <Whats/>

      {/* Footer / Quem somos / Contatos */}
      <Footer/>

    </div>
  );
}

export default App;
