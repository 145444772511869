import React from 'react'

function Whats() {
  return (
    <div class="whats">
        <a href="https://wa.me//5548996966465?text=Olá!%20Tenho%20interesse%20em%20encomendar%20algumas%20marmitas.%20">
            <i class="fa-brands fa-whatsapp"></i>
        </a>
    </div>
  )
}

export default Whats
